$max-width: 590px;
$min-width: 591px;

$max-width2: 899px;
$min-width2: 900px;

$background: #e4e5e0;
$color: #666a58;
$lightColor: #7e836e;
$max-width: 1260px;
$neumoShadow: 2px 2px 2px rgba(0, 0, 0, 0.2),
  -2px -2px 2px rgba(255, 255, 255, 0.8);

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  color: $color;
}

#lp {
  position: relative;
  background: $background;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 15;
  width: 100%;
  overflow: hidden;
}

.lpSection {
  min-height: 100vh;
  width: 100%;
  max-width: $max-width;
}

.lpHero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: $max-width2) {
    flex-wrap: wrap;
  }
  @media (min-width: $min-width2) {
    margin: 0 auto;
  }
  &_title {
    text-shadow: 0 0 5px #fff;
    font-weight: 200;
    @media (max-width: $max-width2) {
      font-size: 1800%;
      width: 100%;
      text-align: center;
      line-height: 0.78;
    }
    @media (min-width: $min-width2) {
      font-size: 3200%;
      line-height: 0.68;
    }
    &_l {
      color: $lightColor;
      display: block;
      @media (max-width: $max-width2) {
        margin-left: -0.37em;
      }
      @media (min-width: $min-width2) {
      }
    }
    &_p {
      color: $color;
      display: block;
      @media (max-width: $max-width2) {
        margin-left: 0.37em;
      }
      @media (min-width: $min-width2) {
        margin-left: 0.47em;
      }
    }
  }
  &_content {
    width: 100%;
    @media (max-width: $max-width2) {
      margin-top: -160px;
    }
    @media (min-width: $min-width2) {
      text-align: center;
    }
    &_text {
      @media (max-width: $max-width2) {
        // text-align: center;
        font-size: 150%;
      }
      @media (min-width: $min-width2) {
        font-size: 200%;
      }
      &_span {
        display: block;
        @media (max-width: $max-width2) {
          font-size: 200%;
        }
        @media (min-width: $min-width2) {
          font-size: 250%;
        }
      }
    }
  }
}

.lpSecondary {
  @media (max-width: $max-width2) {
  }
  @media (min-width: $min-width2) {
    display: flex;
    align-items: center;
  }
  &_l {
    &_title {
      @media (max-width: $max-width2) {
        font-size: 200%;
        font-weight: bold;
        text-align: center;
      }
      @media (min-width: $min-width2) {
        font-size: 200%;
      }
    }
    &_ul {
      margin: 30px auto;
      &_li {
        background: rgba(255, 255, 255, 0.5);
        padding: 10px;
        margin: 10px auto;
        border-radius: 10px;
      }
    }
  }
  &_r {
    @media (max-width: $max-width2) {
      width: 100%;
    }
    @media (min-width: $min-width2) {
      width: 300px;
    }
    &_img {
      width: 100%;
      max-width: 300px;
      display: block;
      margin: auto;
      @media (max-width: $max-width2) {
      }
      @media (min-width: $min-width2) {
      }
    }
  }
}

// .lpSecondary {
//   width: $max-width;
//   margin: auto;
//   min-height: 100vh;
//   @media (max-width: $max-width2) {
//   }
//   @media (min-width: $min-width2) {
//     display: flex;
//     align-items: center;
//   }
//   &_r {
//     @media (max-width: $max-width2) {
//       width: 100%;
//     }
//     @media (min-width: $min-width2) {
//     }
//     width: 300px;
//     &_img {
//       width: 100%;
//       display: block;
//       @media (max-width: $max-width2) {
//         max-width: 230px;
//         margin: 40px auto 0 auto;
//       }
//       @media (min-width: $min-width2) {
//         max-width: 300px;
//       }
//     }
//   }

//   &_l {
//     @media (max-width: $max-width2) {
//       width: 100%;
//     }
//     @media (min-width: $min-width2) {
//       width: calc(100% - 350px);
//     }

//     &_title {
//       background: rgba(255, 255, 255, 0.5);
//       padding: 2px 30px;

//       @media (max-width: $max-width2) {
//         font-size: 150%;
//       }
//       @media (min-width: $min-width2) {
//         font-size: 280%;
//       }
//       &_strong {
//         font-size: 350%;
//         font-weight: 200;
//       }
//     }
//     &_ul {
//       font-size: 120%;
//       list-style: none;
//       margin-top: 50px;
//       &_li {
//         margin-top: 20px;
//         margin-left: 1em;
//       }
//     }
//   }
// }

.lpOpt {
  width: 100%;
  max-width: $max-width;
  margin: auto;
  &_title {
    text-align: center;
    font-size: 200%;
    margin-bottom: 20px;
  }
  &_box {
    @media (max-width: $max-width2) {
      width: 100%;
      padding: 40px;
      margin-bottom: 30px;
    }
    @media (min-width: $min-width2) {
      width: calc(33% - 10px);
      padding: 40px 20px;
    }
    border-radius: 10px;
    svg {
      display: block;
      margin: auto;
      margin-bottom: 30px;
      opacity: 0.8;
    }
    &_title {
      font-size: 100%;
      margin-bottom: 10px;
      text-align: center;
      font-weight: bold;
      &::after {
        content: '最適';
        padding-bottom: 1px;
        border-bottom: 2px solid #666a58;
      }
    }
    &_body {
      color: $lightColor;
    }
  }
}

.lpTypeText {
  min-height: 75vh;
  padding-top: 90px;
  padding-bottom: 90px;
  &_title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
  }
  &_body,
  &_ul {
    width: 100%;
    max-width: 680px;
    margin: auto;
    color: $lightColor;
  }
  &_ul {
    margin: 30px auto;
    &_li {
      color: $lightColor;
    }
  }
}

.neumo {
  box-shadow: $neumoShadow;
}

.lpTypeNeumo {
  box-shadow: $neumoShadow;
  width: 100%;
  max-width: $max-width;
  margin: auto;
  padding: 60px;
  &_title {
    text-align: center;
  }
}

.lpShadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
