@import 'setting.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  padding: 10px 20px;
  z-index: 1000;
  &_main {
    text-decoration: none;
    &_logo {
      @media (max-width: $max-width2) {
        margin-right: 5px;
        width: 22px;
      }
      @media (min-width: $min-width2) {
        margin-right: 10px;
        width: 28px;
      }
    }
    &_title {
      color: #fff;
      font-size: 100%;
      font-weight: bold;
      @media (max-width: $max-width2) {
        letter-spacing: 2px;
      }
      @media (min-width: $min-width2) {
        letter-spacing: 3px;
      }
    }
  }
  &_menu {
    color: #fff;
    text-decoration: none;
    font-size: 100%;
    font-weight: bold;
    @media (max-width: $max-width2) {
      letter-spacing: 2px;
      margin-left: 20px;
    }
    @media (min-width: $min-width2) {
      letter-spacing: 3px;
      margin-left: 30px;
    }
  }
  &_bar {
    position: absolute;
    height: 30px;
    top: 18px;
    left: 0;
    z-index: -1;
    min-width: 250px;
    @media (max-width: $max-width2) {
      width: 61.8%;
    }
    @media (min-width: $min-width2) {
      width: 38.2%;
    }
  }
}

#main {
  position: relative;
  z-index: 15;
}

#heroImg {
  position: fixed;
  top: 0;
  z-index: -5;
  width: 100%;
  min-width: 1920px;
  @media (max-width: $max-width2) {
    left: -900px;
  }
  @media (min-width: $min-width2) {
    right: 0;
  }
}

#heroGeo {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  color: #fff;
  &_link {
    color: #fff;
    margin-left: 10px;
  }
}

.titleLeft {
  font-size: 400%;
  margin-left: 5%;
}

.subTitleLeft {
  margin-left: 5%;
}

.hero {
  position: relative;
  z-index: 10;
  height: 90vh;
  min-height: 600px;
  width: 90%;
  margin: auto;
  &_title {
    font-size: 6vw;
    color: #fff;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    @media (max-width: $max-width2) {
      font-size: 300%;
    }
  }
  &_body {
    color: #fff;
  }
}

.secondary {
  position: relative;
  width: calc(100% - 20px);
  height: 95vh;
  &_title {
    width: 100%;
    font-size: 5vw;
    text-align: right;
    color: #fff;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    @media (max-width: $max-width2) {
      font-size: 180%;
      line-height: 2;
    }
    &_em {
      margin-left: 10px;
    }
    &_em,
    &_strong {
      padding: 5px 5px 0 10px;
      background: $mainColorAlpha;
      backdrop-filter: contrast(60%);
      font-weight: normal;
    }
  }
}

.plans {
  &_item {
    position: relative;
    padding: 30px 10px;
    background: #fff;
    text-align: center;

    @media (max-width: $max-width2) {
      width: calc(50% - 10px);
      margin: 0 5px 10px 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    @media (min-width: $min-width2) {
      width: calc(20% - 20px);
      margin: 0 10px 20px 10px;
      min-width: 200px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    }
    &_planName {
      font-size: 90%;
      font-weight: bold;
    }
    &_price {
      font-size: 180%;
      margin-top: 10px;
      &_yen {
        font-size: 50%;
        margin-right: 5px;
      }
    }
    &_per {
      margin-bottom: 30px;
    }
    &_body {
      font-size: 85%;
    }
    &_ul {
      font-size: 85%;
      margin: 25px 0;
      padding: 20px 2px;
      border-top: 1px solid #6e1e50;
      border-bottom: 1px solid #6e1e50;
      list-style: none;
    }
    &_button {
      background: #6e1e50;
      border: none;
      color: #fff;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 100%;
    }
  }
  &_toContact {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background: #6e1e50;
    border-radius: 100px;
    margin: 30px auto 0 auto;
    max-width: 320px;
    padding: 10px;
  }
}

.plan {
  position: fixed;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 200;
  animation-name: fadeInOut;
  animation-duration: 0.5s;
  &_box {
    position: fixed;
    inset: 0;
    min-height: 300px;
    height: 94%;
    max-height: 600px;
    background: #fff;
    width: 90%;
    overflow: scroll;
    max-width: 980px;
    padding: 40px 20px;
    z-index: 2;
    margin: auto;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.5);
    &_title {
      font-size: 200%;
      text-align: center;
      font-weight: bold;
    }
    &_catchCopy {
      text-align: center;
    }
    &_price {
      font-weight: bold;
      font-size: 250%;
      text-align: center;
      color: $mainColor;
      margin-top: 30px;
    }
    &_body {
      max-width: 650px;
      margin: 20px auto 0 auto;
      &_text {
        margin: 15px 0;
      }
    }
  }
  &_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(3px);
    z-index: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flow {
  &_box {
    max-width: 920px;
    margin-bottom: 60px;
    @media (max-width: $max-width2) {
      padding: 0 10px;
    }
    &_title {
      @media (max-width: $max-width2) {
        font-size: 190%;
      }
      @media (min-width: $min-width2) {
        font-size: 220%;
      }
      margin-bottom: 10px;
      background: rgba(255, 255, 255, 0.8);
      padding: 0 5px;
      color: $darkColor;
    }
    &_body {
      background: rgba(255, 255, 255, 0.8);
      padding: 0 5px;
    }
  }
}

.qa {
  &_box {
    margin: 60px 0;
    max-width: 920px;
    @media (max-width: $max-width2) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (min-width: $min-width2) {
      padding-left: 30px;
      padding-right: 30px;
    }
    &_q,
    &_a {
      &_l {
        width: 20px;
      }
      &_r {
        flex: 1;
      }
    }
    &_q {
      color: $darkColor;
      margin-bottom: 10px;
      &_l {
        font-weight: bold;
      }
      &_r {
        font-weight: bold;
      }
    }
  }
}

.homeSection {
  position: relative;
  z-index: 10;
  margin-top: 90px;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  &_title {
    @media (max-width: $max-width2) {
      font-size: 800%;
    }
    @media (min-width: $min-width2) {
      font-size: 1000%;
    }
    font-weight: 200;
    line-height: 1;
  }
  &_subTitle {
    margin-left: 4px;
    @media (max-width: $max-width2) {
      font-size: 100%;
    }
    @media (min-width: $min-width2) {
      font-size: 120%;
    }
    &_r {
      text-align: right;
    }
    &_span {
      padding: 5px 10px;
      color: $background-gray;
      background: $color;
    }
  }
  &_right {
    text-align: right;
  }
}

@media (max-width: $max-width2) {
  .container {
    padding: 90px 10px;
  }
}
@media (min-width: $min-width2) {
  .container {
    padding: 90px 30px;
  }
}

.container-l {
  width: 100%;
  max-width: $container-width-l;
  margin-left: auto;
  margin-right: auto;
}

.background-gray {
  background: $background-gray;
}

.background-white {
  background: $background-white;
}

.container-shadow {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
}

.budoul,
.budour {
  position: absolute;
  background: $mainColor;
  z-index: 0;
}

@media (max-width: $max-width2) {
  .budoul,
  .budour {
    width: 200px;
    height: 200px;
    border-radius: 100px;
  }

  .budoul {
    left: -130px;
  }

  .budour {
    right: -130px;
  }
}
@media (min-width: $min-width2) {
  .budoul,
  .budour {
    bottom: 20px;
    width: 400px;
    height: 400px;
    border-radius: 200px;
  }

  .budoul {
    left: -240px;
  }

  .budour {
    right: -240px;
  }
}

.ika {
  position: relative;
  background: #fff;
  width: 100%;
  padding: 60px 20px 0 20px;
  overflow: hidden;
  z-index: 1;
  &_img {
    position: relative;
    width: 100%;
    max-width: 600px;
    display: block;
    margin: auto;
    z-index: 2;
  }
}

.contactLink {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  display: block;
  text-align: center;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.8);
  padding: 5px 10px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 2px;
  // transform: rotate(-15deg);
}

.footer {
  position: relative;
  backdrop-filter: sepia(80%);
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  min-height: 300px;
  &_nav {
    width: 100%;
    text-align: center;
    &_link {
      color: #fff;
      text-decoration: none;
      @media (max-width: $max-width2) {
        margin: 0 8px;
      }
      @media (min-width: $min-width2) {
        margin: 0 20px;
        font-weight: bold;
      }
    }
    &_domain {
      font-size: 100%;
      color: #fff;
      margin-top: 90px;
    }
  }
}

.privacypolicy {
  padding-top: 120px;
  padding-bottom: 120px;
  background: #fff;
  &_title {
    color: $darkColor;
    @media (max-width: $max-width2) {
      font-size: 200%;
    }
    @media (min-width: $min-width2) {
      font-size: 4vw;
    }
    text-align: center;
  }
  &_contents {
    width: 100%;
    max-width: 980px;
    margin: auto;
    line-height: 1.3;
    &_h2 {
      @media (max-width: $max-width2) {
        font-size: 130%;
      }
      @media (min-width: $min-width2) {
        font-size: 150%;
      }
      color: $darkColor;
      margin-top: 60px;
      margin-bottom: 10px;
    }
    &_h3 {
      color: $darkColor;
      font-size: 100%;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    &_ol {
      margin-top: 20px;
      margin-left: 1.6em;
    }
  }
}

.contact {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 30px);
  max-width: 600px;
  // background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: hue-rotate(20deg);
  transform: translateY(-50%) translateX(-50%);
  @media (max-width: $max-width2) {
    padding: 30px;
  }
  @media (min-width: $min-width2) {
    padding: 50px;
  }
  &_title {
    font-size: 200%;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
  &_box {
    width: 100%;
    margin-bottom: 30px;
    &_textField {
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      border-radius: 5px;
    }
  }
  &_submit {
    width: 80%;
    display: block;
    margin: auto;
    background: rgba(255, 255, 255, 0.5);
    color: #000;
    font-weight: bold;
    padding: 20px;
    border-radius: 100px;
    border: none;
    font-size: 100%;
    transition: 0.3s;
    &:hover {
      background: rgba(255, 255, 255, 0.8);
      transition: 0.3s;
    }
  }
}
