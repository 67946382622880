$mainColor: #6e1e50;
$mainColorAlpha: rgba(110, 30, 80, 0.8);

$container-width-l: 1200px;
$container-width-m: 1024px;
$container-width-s: 820px;

// Media Query用サイズ
$max-width: 590px;
$min-width: 591px;
$max-width2: 899px;
$min-width2: 900px;

// backgroun¥
$background-gray: #f3f3f3;
$background-white: #fff;

// font color
$color: #251b3b;
$darkColor: #333333;

//
$headerHeight: 65px;
