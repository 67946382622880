@import 'assets/css/setting.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
    'Hiragino Sans', Meiryo, sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #251b3b;
  font-weight: normal;
}

p,
ul {
  color: #37295a;
}

.flex {
  display: flex;
}

.flex-center {
  align-items: center;
}

.flex-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex1 {
  flex: 1;
}
